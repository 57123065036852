import type { ConfigEnvs } from '@bluecodecom/vas/config';

import { environment } from './environments/environment';

const VAS_FALLBACK_ENV = 'bci-int';

const getEnv = (): ConfigEnvs['env'] => {
  if (environment.local) {
    return 'local';
  }

  return (
    (process.env['NX_PUBLIC_DEPLOYMENT_TARGET'] as ConfigEnvs['env']) ||
    VAS_FALLBACK_ENV
  );
};

const getEnvs = () => ({
  env: getEnv(),
});

export default getEnvs;
