import { lazy, Suspense, useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import '@bluecodecom/fonts';

import { themeService } from '@bluecodecom/common/theme';

import getEnvs from './envs';
import './i18n';

const jwt = new URLSearchParams(window.location.search).get('jwt') || '';
const lang = new URLSearchParams(window.location.search).get('lang') || '';

const Vas = lazy(() => import('@bluecodecom/vas'));

const App = () => {
  const handleLoaded = useCallback(() => {
    document.getElementById('initial-loader')?.remove();
  }, []);

  const handleUpdateTheme = useCallback((isDarkMode: boolean) => {
    themeService.setTheme(isDarkMode ? 'dark' : 'light');
  }, []);

  return (
    <HashRouter>
      <Suspense>
        <Vas
          jwt={jwt}
          overrideLanguage={lang}
          envs={getEnvs()}
          onUpdateTheme={handleUpdateTheme}
          sentryDsn={process.env['NX_PUBLIC_SENTRY_DSN']}
          version={process.env['NX_PUBLIC_APP_VERSION']}
          onLoaded={handleLoaded}
        />
      </Suspense>
    </HashRouter>
  );
};

createRoot(document.getElementById('root') as HTMLElement).render(<App />);
